<template>
  <div v-loading="pageLoading" class="vehicleCheckConfig">
    <el-tabs
      v-model="configData.channelNo"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in channelList"
        :key="index"
        :label="item.channelName"
        :name="item.channelNo"
      >
      </el-tab-pane>
    </el-tabs>
    <template v-if="channelList.length">
      <div class="config">
        <header>车辆例检规则</header>
        <div class="item">
          <label>例检方式</label>
          <div>
            <el-radio
              v-model="configData.inspectionWay"
              :label="0"
              @input="selectConfig"
              >上传例检单</el-radio
            >
            <el-radio
              v-model="configData.inspectionWay"
              :label="1"
              @input="selectConfig"
              >系统填写</el-radio
            >
          </div>
        </div>
        <div class="item2">
          <label>例检要求</label>
          <div>
            <div class="check">
              <el-radio
                v-model="configData.inspectionRequest"
                :label="0"
                @input="selectConfig"
                >日检</el-radio
              ><span>例检结果24小时内报班有效</span>
            </div>
            <div class="check">
              <el-radio
                v-model="configData.inspectionRequest"
                :label="1"
                @input="selectConfig"
                >趟检</el-radio
              ><span>例检结果24小时内报班有效，排班发车后失效</span>
            </div>
          </div>
        </div>
        <header>检查项目与检查内容</header>
        <el-button
          v-if="listFind('新建')"
          type="primary"
          size="small"
          @click="addBtn"
          >新建</el-button
        >
        <div
          v-for="(item, index) in configData.routineInspectionContentDTOS"
          :key="index"
          class="item"
        >
          <label>{{ item.inspectionItem }}</label>
          <div class="list">
            <div v-for="(e, i) in item.inspectionContent" :key="i">
              {{ e }}
            </div>
          </div>
          <div class="button">
            <el-button
              v-if="listFind('编辑')"
              type="primary"
              size="small"
              @click="editBtn(item, index)"
              style="margin-right: 10px"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除此检查项目吗？"
              @confirm="deleteBtn(item)"
            >
              <el-button
                v-if="listFind('删除')"
                type="danger"
                size="small"
                slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
    <charterPopup
      v-if="show"
      width="500"
      :header-text="headerText"
      max-height="600px"
      confirm-text="保存"
      @closePopupFun="closePopupFun"
      @confirmFun="confirm"
    >
      <div class="main">
        <el-form
          ref="ruleForm"
          :model="form"
          label-position="left"
          label-width="110px"
          :rules="rules"
        >
          <el-form-item
            label="检查项目"
            prop="inspectionItem"
            style="margin-bottom: 36px"
          >
            <el-input v-model="form.inspectionItem" :maxlength="10"></el-input>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in form.inspectionContent"
            :key="index"
            :label="index == 0 ? '检查内容' : ''"
            :rules="{
              required: true,
              message: '请输入检查内容',
              trigger: 'blur',
            }"
            :prop="'inspectionContent.' + index + '.value'"
          >
            <div class="content">
              <el-input v-model="item.value" :maxlength="10"></el-input>
              <i class="el-icon-close" @click="deleteItem(index)"></i>
            </div>
          </el-form-item>
          <div class="add">
            <span @click="addItem()">+ 添加检查内容</span>
          </div>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  saveOrUpdateOrDeleteConfigAPI,
  getCurrentUserChannelInfoAPI,
  queryConfigAPI,
  deleteContentByIdAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelList: [],
      maxLength: 0,
      show: false,
      headerText: "",
      rules: {
        inspectionItem: {
          required: true,
          message: "请填写检查项目",
          trigger: "blur",
        },
        inspectionContent: [{ value: "" }],
      },
      configData: {
        inspectionWay: 0,
        inspectionRequest: 0,
        channelNo: "",
        id: null,
        routineInspectionContentDTOS: [],
      },
      form: {
        inspectionItem: "",
        inspectionContent: [{ value: "" }],
      },
      itemIndex: 0,
    };
  },
  mounted() {
    this.getQueryChannelList();
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data || [];
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            } else {
              this.configData.channelNo = res.data[0].channelNo;
            }
          }
          this.pageLoading = false;
          this.getConfigForm();
        })
        .catch(() => {
          this.pageLoading = false;
          this.getConfigForm();
        });
    },
    //获取配置数据
    getConfigForm() {
      queryConfigAPI(this.configData.channelNo).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data) {
            this.configData = res.data;
          } else {
            const obj = {
              inspectionWay: 0,
              inspectionRequest: 0,
              channelNo: this.configData.channelNo,
              routineInspectionContentDTOS: [],
            };
            saveOrUpdateOrDeleteConfigAPI(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.configData = res.data;
              }
            });
          }
        }
      });
    },
    handleClick(tab) {
      this.configData.channelNo = tab.name;``
      this.getConfigForm();
    },
    selectConfig() {
      saveOrUpdateOrDeleteConfigAPI(this.configData).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("更新配置成功");
          this.getConfigForm();
        }
      });
    },
    addBtn() {
      this.show = true;
      this.headerText = "新建检查项目与检查内容";
    },
    editBtn(item, index) {
      this.show = true;
      this.headerText = "编辑检查项目与检查内容";
      this.form = this.deepClone(item);
      this.itemIndex = index;
      this.form.inspectionContent = item.inspectionContent.map((item) => {
        return { value: item };
      });
    },
    deleteBtn(item) {
      deleteContentByIdAPI(item.id).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("删除检查项目配置成功");
          this.getConfigForm();
        }
      });
    },
    deleteItem(index) {
      if (index) this.form.inspectionContent.splice(index, 1);
    },
    addItem() {
      this.form.inspectionContent.push({
        inspectionContent: "",
        inspectionItem: [{ value: "" }],
      });
    },
    closePopupFun() {
      this.show = false;
      this.form = {
        inspectionItem: "",
        inspectionContent: [{ value: "" }],
      };
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.headerText == "新建检查项目与检查内容") {
            const obj = this.form;
            obj.inspectionContent = obj.inspectionContent.map(
              (item) => item.value
            );
            this.configData.routineInspectionContentDTOS.push(obj);
            saveOrUpdateOrDeleteConfigAPI(this.configData).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("新建检查项目成功");
                this.show = false;
                this.getConfigForm();
              }
            });
          } else {
            const obj = this.form;
            obj.inspectionContent = obj.inspectionContent.map(
              (item) => item.value
            );
            this.configData.routineInspectionContentDTOS[this.itemIndex] =
              this.form;
            saveOrUpdateOrDeleteConfigAPI(this.configData).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("更新检查项目成功");
                this.show = false;
                this.form = {
                  inspectionItem: "",
                  inspectionContent: [{ value: "" }],
                };
                this.getConfigForm();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicleCheckConfig {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .config {
    padding: 0 30px;
    box-sizing: border-box;
    width: 100%;
    header {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0;
    }
    .item {
      width: 660px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      label {
        width: 130px;
        font-weight: bold;
        flex-shrink: 0;
      }
      .list {
        flex: 1;
        display: grid;
        margin-right: 50px;
        row-gap: 8px;
        column-gap: 10px;
        grid-template-columns: repeat(4, 25%);
        div {
          word-break: break-all;
        }
      }
      .button {
        flex-shrink: 0;
      }
    }
    .item2 {
      width: 70%;
      display: flex;
      padding-top: 20px;
      label {
        width: 130px;
        font-weight: bold;
        flex-shrink: 0;
      }
      .check {
        margin-bottom: 15px;
        span {
          color: rgb(134, 134, 134);
        }
      }
    }
  }
  .main {
    padding: 30px 50px;
    .content {
      display: flex;
      align-items: center;
      .el-icon-close {
        font-weight: bold;
        color: red;
        font-size: 16px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
    .add {
      color: rgb(0, 195, 255);
      font-size: 15px;
      margin-left: 110px;
      margin-bottom: 18px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
