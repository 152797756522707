var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "vehicleCheckConfig",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.configData.channelNo,
            callback: function ($$v) {
              _vm.$set(_vm.configData, "channelNo", $$v)
            },
            expression: "configData.channelNo",
          },
        },
        _vm._l(_vm.channelList, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.channelName, name: item.channelNo },
          })
        }),
        1
      ),
      _vm.channelList.length
        ? [
            _c(
              "div",
              { staticClass: "config" },
              [
                _c("header", [_vm._v("车辆例检规则")]),
                _c("div", { staticClass: "item" }, [
                  _c("label", [_vm._v("例检方式")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          on: { input: _vm.selectConfig },
                          model: {
                            value: _vm.configData.inspectionWay,
                            callback: function ($$v) {
                              _vm.$set(_vm.configData, "inspectionWay", $$v)
                            },
                            expression: "configData.inspectionWay",
                          },
                        },
                        [_vm._v("上传例检单")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          on: { input: _vm.selectConfig },
                          model: {
                            value: _vm.configData.inspectionWay,
                            callback: function ($$v) {
                              _vm.$set(_vm.configData, "inspectionWay", $$v)
                            },
                            expression: "configData.inspectionWay",
                          },
                        },
                        [_vm._v("系统填写")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item2" }, [
                  _c("label", [_vm._v("例检要求")]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "check" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            on: { input: _vm.selectConfig },
                            model: {
                              value: _vm.configData.inspectionRequest,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configData,
                                  "inspectionRequest",
                                  $$v
                                )
                              },
                              expression: "configData.inspectionRequest",
                            },
                          },
                          [_vm._v("日检")]
                        ),
                        _c("span", [_vm._v("例检结果24小时内报班有效")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "check" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            on: { input: _vm.selectConfig },
                            model: {
                              value: _vm.configData.inspectionRequest,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configData,
                                  "inspectionRequest",
                                  $$v
                                )
                              },
                              expression: "configData.inspectionRequest",
                            },
                          },
                          [_vm._v("趟检")]
                        ),
                        _c("span", [
                          _vm._v("例检结果24小时内报班有效，排班发车后失效"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("header", [_vm._v("检查项目与检查内容")]),
                _vm.listFind("新建")
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.addBtn },
                      },
                      [_vm._v("新建")]
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.configData.routineInspectionContentDTOS,
                  function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("label", [_vm._v(_vm._s(item.inspectionItem))]),
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(item.inspectionContent, function (e, i) {
                          return _c("div", { key: i }, [
                            _vm._v(" " + _vm._s(e) + " "),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "button" },
                        [
                          _vm.listFind("编辑")
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editBtn(item, index)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "确定删除此检查项目吗？" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.deleteBtn(item)
                                },
                              },
                            },
                            [
                              _vm.listFind("删除")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "danger",
                                        size: "small",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }
                ),
              ],
              2
            ),
          ]
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
      _vm.show
        ? _c(
            "charterPopup",
            {
              attrs: {
                width: "500",
                "header-text": _vm.headerText,
                "max-height": "600px",
                "confirm-text": "保存",
              },
              on: { closePopupFun: _vm.closePopupFun, confirmFun: _vm.confirm },
            },
            [
              _c(
                "div",
                { staticClass: "main" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        "label-position": "left",
                        "label-width": "110px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "36px" },
                          attrs: { label: "检查项目", prop: "inspectionItem" },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: 10 },
                            model: {
                              value: _vm.form.inspectionItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "inspectionItem", $$v)
                              },
                              expression: "form.inspectionItem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.form.inspectionContent,
                        function (item, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: index,
                              attrs: {
                                label: index == 0 ? "检查内容" : "",
                                rules: {
                                  required: true,
                                  message: "请输入检查内容",
                                  trigger: "blur",
                                },
                                prop: "inspectionContent." + index + ".value",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content" },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: 10 },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _c("div", { staticClass: "add" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.addItem()
                              },
                            },
                          },
                          [_vm._v("+ 添加检查内容")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }